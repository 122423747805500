import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';

import Layout from '@/components/common/layout';
import HubspotForm from '@/components/common/hubspot-form';

const PrizeGiveawayBody = ({ data }) => {
  const {
    title,
    mainImage,
    textBelowImage,
    hubspotForm,
    _rawTermsAndConditions,
  } = data;

  return (
    <Layout>
      <div className="m-auto mx-auto my-10 flex h-full max-w-[1440px] flex-col px-4 sm:px-10">
        <h1 className="text-center text-[36px] md:text-[40px] lg:text-[56px]">
          {title}
        </h1>

        <div className="my-10 flex flex-col lg:flex-row">
          <div className="w-full p-4 lg:w-1/2">
            <GatsbyImage
              alt={title}
              image={mainImage.asset.gatsbyImageData}
              className="w-full rounded-2xl"
            />
          </div>
          <div className="flex w-full flex-col justify-between p-4 lg:w-1/2">
            <h2 className="text-[22px] md:text-[28px] lg:text-[36px]">
              {textBelowImage}
            </h2>
            <HubspotForm
              portalId={hubspotForm.hubspotPortalId}
              formId={hubspotForm.hubspotFormId}
              redirectToThankYouPage={false}
              hubspotFormVideoUrl={hubspotForm.hubspotFormVideoUrl}
            />
          </div>
        </div>

        <p className="mx-auto my-4 text-xs text-[#b5b5b5]">
          <PortableText value={_rawTermsAndConditions} />
        </p>
      </div>
    </Layout>
  );
};

export default PrizeGiveawayBody;
