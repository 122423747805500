import React, { useEffect, useState } from 'react';

const BlogEmbedHubspotForm = ({
  portalId,
  formId,
  redirectToThankYouPage,
  hubspotFormVideoUrl,
}) => {
  const [submitSucess, setSubmitSucess] = useState(false);

  const createForm = () => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: portalId,
      formId: formId,
      target: `#form_${formId}`,
    });
  };

  const handleSuccess = () => {
    setSubmitSucess(true);

    if (redirectToThankYouPage && hubspotFormVideoUrl !== '') {
      // set session storage video url item
      localStorage.setItem('thVideoUrl', hubspotFormVideoUrl);
    }
  };

  const onHubSubmit = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted' &&
      event.data.id === formId
    ) {
      handleSuccess();
    }
  };

  useEffect(() => {
    window.addEventListener('message', onHubSubmit);
    if (!window.hbspt) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm();
        }
      });
    } else {
      createForm();
    }
    return () => {
      window.removeEventListener('message', onHubSubmit);
    };
  }, []);

  return (
    <div className="bg-white pt-3">
      <div className="hs-form-reg rounded-lg border border-purple-100 px-6 pb-4 pt-5">
        <div id={`form_${formId}`} className="h-full min-h-[300px] w-full" />
        {/* need to update in the future to handle all the cases */}
      </div>
    </div>
  );
};

export default BlogEmbedHubspotForm;
